:root {
  --main-bg-color: #fff;
  --highlight-bg-color: #3697fc;
  --footer-bg-color: #000;
  --main-text-color: #000;
  --secondary-text-color: #ccc;
  --footer-text-color: #ccc;

  --heading-font: 'Barlow', sans-serif;
  --main-font: 'Crimson Text', serif;

  --normal-font-size: 20px;
  --normal-font-weight: 500;
}

[data-theme="dark"] {
  --main-bg-color: linear-gradient(90deg,#02070d 25%,#09203a 100%);
  --highlight-bg-color: #4c93dc;
  --footer-bg-color: #eee;
  --main-text-color: #eee;
  --secondary-text-color: #ccc;
  --footer-text-color: #333;

  --heading-font: 'Barlow', sans-serif;
  --main-font: 'Crimson Text', serif;

  --normal-font-size: 20px;
  --normal-font-weight: 500;
}

html{
  scroll-behavior: smooth;
}

a{
  transition: 0.2s all;
  color: var(--main-text-color);
  text-decoration: none;
}

a:not(.no-highlight):hover{
  color: #fff;
  background-color: var(--highlight-bg-color);
  padding: 0px 5px;
  text-decoration: none;
}

a.no-highlight:hover{
  text-decoration: none;
}

img{
  max-width: 100%;
  width: auto;
  height: auto;
}

p{
  color: var(--main-text-color);
}

main{
  font-family: var(--main-font);
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-14px);
  }
  60% {
    transform: translateY(-7px);
  }
}


body {
  margin: 0;
  font-family: var(--heading-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-bg-color);
  font-size: var(--normal-font-size);
}

main{
  padding-bottom: 9vh;
}

/* Nav */
header{
  position: fixed;
  top: 0px;
  left: 0px;
  height: 91vh;
  z-index: 99;
}

.mode-switch{
  color: var(--main-text-color);
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  font-size: 16px;
}

.mode-switch:hover{
  background-color: var(--highlight-bg-color);
  color: #fff;
  transform: scale(1.1);
}

nav a{
  margin-bottom: 5px;
  color: var(--secondary-text-color);
  font-weight: var(--normal-font-weight);
}

nav a.active{
  background-color: var(--highlight-bg-color);
  color: #fff;
  padding: 0px 5px;
}
/* Nav */

/* Footer */
footer{
  background-color: var(--footer-bg-color);
  height: 9vh;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

footer a{
  color: var(--footer-text-color);
  font-weight: var(--normal-font-weight);
  padding: 0px 5px;
}

footer a:hover{
  transform: scale(1.1);
}
/* Footer */

.section-container{
  min-height: 91vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Home */
#home h1{
  background-color: var(--highlight-bg-color);
  color: #fff;
  display: inline-block;
  padding: 0px 20px;
  font-size: 80px;
}

#home h2{
  color: var(--main-text-color);
  font-size: 60px;
}

#home p{
  position: absolute;
  bottom: 20%;
  width: 100%;
  left: 0px;
}

#home a{
  font-size: 30px;
  transition: background-color 0.2s, transform 0.2s;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin: 0px auto;
}
/* Home */

/* About */
#about p{
  font-size: 18px;
  color: var(--main-text-color);
}

#about span{
  background-color: var(--highlight-bg-color);
  color: #fff;
  padding: 0px 5px;
}
#about a{
  text-decoration: underline;
  transition: 0.2s background-color, 0.2s color, 0.2s padding;
}
#about a:hover{
  text-decoration: none;
}
/* About */

/* Work */
#work img {
  width: 100%;
  object-fit: contain;
  transition: 0.2s all;
}

#work .work-info span{
  padding: 0px 5px;
  background-color: var(--highlight-bg-color);
  color: #fff;
}

#work p{
  font-size: 18px;
}

#work .work-image-wrapper:hover img{
  transform: scale(1.05);
}
/* Work */

/* Responsive */
@media only screen and (max-width: 767px) {
  #home h1 {
    font-size: 60px;
  }
  #home h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 576px) {
  #about p {
    font-size: 16px;
  }
  #home h1 {
    font-size: 43px;
  }
  #home h2 {
    font-size: 30px;
  }

  .mode-switch {
    width: 30px;
    height: 30px;
    font-size: 10px;
    background-color: transparent !important;
    color: var(--main-text-color) !important;
  }
}
/* Responsive */